<template>
  <div>
    <list-template
        :total="total"
        :table-data="tableData"
        :table-config="tableConfig"
        has-back
        @onChangePage="changeCurrentPage"
        @onHandle="handle"
    ></list-template>

    <!--  教师评价的弹出框  -->
    <el-dialog
        :visible.sync="teachersVisible"
        :title="teachersEvaluateData.student_name + '的综合素质评价'"
        width="800rem">
      <el-descriptions v-if="teachersEvaluateData" :column="1" title="">
        <el-descriptions-item label="评价教师">{{ teachersEvaluateData.teacher_name }}</el-descriptions-item>
        <el-descriptions-item label="评价时间">{{ teachersEvaluateData.created_at }}</el-descriptions-item>
        <el-descriptions-item label="评价内容">{{ teachersEvaluateData.content }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"teachers-evaluate","meta":{"title":"班主任综合素质评价","style":1}}},
  data(){
    return {
      dialogVisible:false,
      battleCont:{},
      // 表格配置
      tableConfig: [
        {
          prop:"content",
          label:"评价内容",
          showOverflowTooltip: true
        },
        {
          prop:"teacher_name",
          label:"评价教师姓名",
          width: 300
        },
        {
          prop:"created_at",
          label:"评价时间",
          width: 300
        },
        {
          handle:true,
          label:"操作",
          width: 100,
          buttons: [
            { type:"view", text:"查看详情" }
          ]
        }
      ],
      // 当前页码
      page: 1,
      total: 0,
      // 表格中的数据
      tableData:[],
      id:null,

      teachersVisible: false,
      teachersEvaluateData: '',
      evaluateList: []
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods:{
    // 分页被更改
    changeCurrentPage(e) {
      this.page = e;
      this.getData();
    },

    getData(){
      this.$_axios.get("/students/evaluate",{params: {student_id: this.id,page:this.page}}).then(res=>{
        let {data} = res.data;
        this.tableData = data;
        let {total} = this.$tools.getPaginationInfo(res.headers);
        this.total = total;
      })
    },
    handle(row){
      const loading = this.$loading({
        lock: true,
        text: '',
        customClass: 'img-upload-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$_axios.get('/students/evaluate-details?id=' + row.id).then(res => {
        this.teachersVisible = true;
        this.teachersEvaluateData = res.data.data
      }).finally(()=>{
        loading.close()
      })
    }
  }
}
</script>

<style scoped>

</style>
